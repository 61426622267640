<template>
  <div>
    <div class="wrapper" id="my-watches">
      <!-- section dashboard -->
      <section id="watches-list" class="section-duke section-dark jpadding jpadding-20 section-unfixed">
        <div class="jtitle jtitle-t40 jtitle-80 duke-style">
          <h1>My Watches</h1>
        </div>
        <div class="grid-2 duke-style">
          <div
            v-for="(watch, index) in watchesList"
            :key="watch.id"
            @click="openWatch(index)"
            class="jcard-duke"
          >
            <!-- <h4><i class="fad fa-watch fa-2x"></i></h4> -->
            <img :src="watchModelsList[watch.modelId].assets.front" class="model-img" alt="" />
            <img :src="watchModelsList[watch.modelId].assets.logo" class="model-logo" alt="" />
            <!-- <h5>{{ watchModelsList[watch.modelId].name }}</h5> -->
            <!-- <p class="m-0"><small>{{$moment($C.getDateFromTimestamp(watch.purchasedOn)).format("MMM YYYY")}}</small></p> -->
            <p class="m-0">
              <small>{{ watch.serial }}</small>
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "MyWatches",
  data() {
    return {
      //userId: auth.currentUser.uid,
      watchesList: [],
      watchModelsList: {}
    };
  },
  methods: {
    async allWatches() {
      const id = auth.currentUser.uid;

      //templist to make sure the model info is ready when the actual list is getting rendered
      const tempWatchlist = [];
      const watchQuerySnapshot = await db
        .collection(C.COLLECTION.WATCHES)
        .where("userId", "==", id)
        .get();
      console.log("watchquerSnap: ");
      console.log(watchQuerySnapshot);
      const promisesList = [];
      for (const doc of watchQuerySnapshot.docs) {
        const watchDoc = doc.data();
        const watchId = doc.id;
        watchDoc.id = watchId;
        console.log(watchId, " => ", watchDoc);
        const watchModelId = watchDoc.modelId;
        if (!promisesList[watchModelId]) {
          console.log("preparing model: " + watchModelId);
          promisesList.push(
            db
              .collection(C.COLLECTION.WATCH_CATALOG)
              .doc(watchModelId)
              .get()
          );
          console.log("promiseslist: ");
          console.log(promisesList);
        }
        tempWatchlist.push(watchDoc);
      }

      const modelQueryResultList = await Promise.allSettled(promisesList);
      modelQueryResultList.forEach(result => {
        if (!result) {
          console.log("ERROR GETTING WATCH MODEL DOCUMENT");
          return;
        }
        const watchModel = result.value.data();
        const watchmodelId = result.value.id;
        this.watchModelsList[watchmodelId] = watchModel;
      });
      this.watchesList = tempWatchlist;
    },
    openWatch(index) {
      console.log("Watchlist: ");
      console.log(this.watchesList);
      console.log("Watchlist index: " + index);

      const watchId = this.watchesList[index].id;
      console.log("Going to watch: " + watchId);
      this.$store.commit("setWatch", this.watchesList[index]);
      this.$router.push({
        name: "MyWatch",
        params: { watchId: watchId }
      });
    }
  },
  async mounted() {
    await this.allWatches();
    console.log("mounted:");
    console.log(this.watchesList);
    console.log(this.watchModelsList);
  }
};
</script>

<style scoped>
#watches-list img.model-img {
  height: 200px;
  width: auto;
}
#watches-list img.model-logo {
  width: 150px;
  height: auto;

  margin-top: 15px;
  margin-bottom: 5px;
}
</style>
